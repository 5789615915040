"use strict";
import Chart from "chart.js";

export default {
  name: "viewmedCharts",
  data: function() {
    return {};
  },
  updated() {
    this.createChart();
  },
  computed: {
    properties() {
      this.$store.commit({
        type: "chart/setProperties",
        loading: false
      });
      return this.$store.getters["chart/getProperties"];
    },
    loading() {
      return this.$store.getters["chart/getLoading"];
    }
  },
  methods: {
    createChart() {
      let ctx = document.getElementById(this.properties.id);
      new Chart(ctx, {
        type: this.properties.type,
        data: this.properties.data,
        options: this.properties.options
      });
    }
  }
};
