import axios from "axios";

async function send_notification(data) {
  const instance  = await axios.create({
    baseURL: `https://gateway.viewmedonline.com/mail/sg`,
    headers:{
      "Access-Control-Allow-Origin": "*",
    },
    json: true,
  })({
    method: 'post',
    data
  });
  
  return instance.data
}



export { send_notification };