<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  data: () => ({
    //
  }),
  mounted() {
    this.checkUserActivity();
  },
  methods: {
    checkUserActivity() {
      // Check if user is afk
      if (sessionStorage.getItem("pussy")) {
        let interval = setInterval(this.sign_out, 240000);

        document.addEventListener("mousemove", () => {
          clearInterval(interval);
          interval = setInterval(this.sign_out, 240000);

          if (!this.showDialog) {
            this.$store.commit({
              // Commit on storage activity
              type: "user/setUser",
              activeUser: true
            });
          }
        });
      }
    },
    clearStore() {
      // Clear Vuex store and session Storage
      this.$store.dispatch("studies/resetStudiesState");
      this.$store.dispatch("registered/resetRegistedState");
      this.$store.dispatch("user/resetUserState");
      this.$store.dispatch("chart/resetChartState");
      sessionStorage.clear();
    },
    sign_out() {
      // Sign Out from management method
      this.$store.commit({
        // Commits on store for showing dialog
        type: "user/setUser",
        showDialog: true,
        activeUser: false
      });
      let lastMinute = setInterval(async () => {
        if (!this.activeUser) {
          await this.clearStore();
          this.$router.push("/");
        } else {
          await clearInterval(lastMinute);
        }
      }, 60000);
    }
  },
  computed: {
    activeUser() {
      return this.$store.getters["user/getActiveUser"];
    },
    showDialog() {
      return this.$store.getters["user/getShowDialog"];
    }
  }
};
</script>
