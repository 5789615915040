import Vue from "vue";
import Vuex from "vuex";

import user from "./modules/user";
import registered from "./modules/registered";
import studies from "./modules/studies";
import chart from "./modules/chart";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user: user,
    registered: registered,
    studies: studies,
    chart: chart
  }
});
