import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        institution: '#8e24aa',
        physician: '#0277bd',
        patient: '#00a887',
        viewmed: '#6d8110',
        viewmed2: '#524c00',
        viewmed3: '#9db93b'
      },
    },
  },
  icons: {
    iconfont: 'mdi' || 'md' || 'fa',
  },
});
