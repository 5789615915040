"use strict";
import filters from "./subComponents/filters/filters.vue";
import showStudies from "./subComponents/showStudies/showStudies.vue";
import showChart from "../stats/viewmedCharts.vue";

export default {
  name: "studies",
  data: () => ({
    show: false
  }),
  components: {
    filters,
    showStudies,
    showChart
  },
  mounted() {
    this.show = true
  },
  methods: {},
  computed: {
    studies() {
      return this.$store.getters["studies/getStudies"];
    },
    chartProperties() {
      return this.$store.getters["chart/getProperties"];
    },
    validateSizeStudies() {
      // validate the size of the card of studies
      if (this.studies) {
        if (this.studies.total > 0) return 5;
        else return 10;
      } else {
        return null;
      }
    },
    validateChartProperties() {
      // validates if chart card should be shown or not
      let value;
      if (this.chartProperties) {
        if (this.chartProperties.data.labels.length > 0) {
          value = true;
        } else {
          value = false;
        }
      } else {
        value = false;
      }
      return value;
    }
  },
  beforeDestroy() {
    this.$store.dispatch("studies/resetStudiesState");
    this.$store.dispatch("chart/resetChartState");
  }
};
