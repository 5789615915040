import api from "./api";
import axios from "axios";

async function imaging_query(data, token) {
  let objAux = {
    method: "post",
    resource: "/imagings/query/v2",
    data
  };

  return (await api.execute(objAux, token)).data.documents;
}
async function get_file(id) {
   let image = (await api.showImaging(id))
   return image
}

async function get_file_new(id) {
  return (await api.showImagingNew(id))
}

async function sharedImagingQuery(data, token) {
  let objAux = {
     method: 'post',
     resource: '/imagings/shared/query',
     data
  }
  return (await (api.execute(objAux, token))).data.documents;
}

async function sharedImagingDelete(id, token) {
  let objAux = {
     method: 'delete',
     resource: `/imagings/shared/delete/${id}`
  }
  return await (api.execute(objAux, token))
}

async function sharedImagingInsert(data, token) {
  let objAux = {
     method: 'post',
     resource: '/imagings/shared/insert',
     data
  }
  return await (api.execute(objAux, token))
}

async function get_token_meddream(data) {
  const instance  = await axios.create({
    baseURL: `https://gateway.viewmedonline.com/imaging/viewer/token`,
    headers:{
      "Authorization": `Bearer ${sessionStorage.getItem("pussy")}`,
      "Access-Control-Allow-Origin": "*",
    },
    json: true,
  })({
    method: 'post',
    data
  });
  
  return instance.data
}



export { imaging_query,get_file,sharedImagingQuery,sharedImagingDelete,sharedImagingInsert,get_token_meddream,get_file_new};