"use strict";
import moment from 'moment'
import {query_action_logs} from '../../services/action_logs'

export default {
  name: "",
  data: () => ({
    dialog: false,
    loading:false,
    data_timeline:null,
    email:null,
    arr_list:[],
    headers: [
      {
        text: 'Email',
        align: 'start',
        value: 'email',
      },
      { text: 'Last login', value: 'date' },
      { text: 'Role', value: 'role' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    date: [],
    menu: false,
    modal: false,
  }),
  async created() {
    this.search()
  },
  methods: {
    showTimeline(x){
      this.dialog = true
      this.data_timeline = x.data
    },
    async search(){
      this.arr_list = []
      this.loading = true
      let date_from = this.date.length == 0 ? moment().format("YYYYMMDD") :moment(this.date[0]).format("YYYYMMDD");
      let date_to = this.date.length == 0 ? moment().format("YYYYMMDD") : moment(this.date[1]).format("YYYYMMDD");
      let email = this.email == null ? 'all' : this.email
      
      let data = await query_action_logs(email,`${date_from}-${date_to}`)
      
      data.forEach((x)=>{
        let obj = {}
        let data = x
        data.date = moment(data.createdAt).format("YYYY-MM-DD HH:mm")

        obj.email = data.user
        obj.date = data.date
        obj.role = data.role

        obj.data = [data]
        let index =this.arr_list.map(function(e) { return e.email; }).indexOf(data.user)
        if(index >=0){
          this.arr_list[index].data.push(data)
        }else{
          this.arr_list.push(obj)
        }
      })
      this.loading = false
    },
    clear(){
      this.date = []
      this.email = null
      this.search()
    },
    save_date(){
      if(this.date.length == 2){
        this.$refs.menu.save(this.date)
        this.search()
      }
    }
  },
  watch: {
    email(val) {
      if(val!= null && val.trim().length == 0){
        this.email = null
      }
      this.search()
    },
    date(val) {
      if(val == null){
        this.date = []
        this.$refs.menu.save(this.date)
        this.search()        
      }
    }
  }
};
