export default {
  lang: {
    title: {
      dashboard: "Dashboard",
      date: "Fecha | Fechas",
      management: "Administrativo",
      messages: "Mensaje | Mensajes",
      password: "Contraseña",
      title: "Viewmed la medicina digitáctil",
      registered: "Registrados",
      sign_in: "Iniciar sesión",
      sign_out: "Cerrar sesión",
      studies: "Estudios",
      user: "Usuario"
    },
    login: {
      passwordRules: "La contraseña es requerida",
      userRules: "El usuario es requerido"
    },
    charts: {
      title_one: "Usuarios registrados por tipo",
      title_two: "Usuarios registrados por fecha"
    },
    table: {
      forename: "Nombre",
      surname: "Apellido",
      email: "Correo",
      date: "Fecha",
      name: "Nombre",
      type: "Tipo",
      category: "Categoria",
      total_users_registered: "Total de usuarios registrados",
      no_data_found: "No se encontraron datos"
    },
    filters: {
      date: {
        day: "Día",
        last_month: "Mes anterior",
        month: "Mes",
        yesterday: "Día anterior"
      },
      gender: {
        female: "Mujer",
        male: "Hombre"
      },
      patient: {
        external: "Externo",
        internal: "Interno"
      },
      user: {
        all: "Todos",
        institution: "Institución",
        patient: "Paciente",
        professional: "Profesional"
      },
      title: {
        by_select: "Por selección",
        by_range: "Por rango",
        by_email: "Por correo",
        from: "Desde",
        gender: "Género",
        select: "Seleccione",
        specialty: "Especialidad",
        type_of_professional: "@:table.type de profesional",
        type_of_institution: "@:table.type de institución",
        type_of_patient: "@:table.type de paciente",
        type_of_user: "@:table.type de usuario",
        until: "Hasta"
      },
      rules: {
        select_an_option: "@:filters.title.select una opción",
        select_a_date: "@:filters.title.select una fecha"
      }
    },
    studies: {
      total_uploaded_studies: "Total de estudios subidos",
      total_x_studies: "Estudios de {name}"
    }
  }
};
