export default {
  lang: {
    title: {
      date: "Date | Dates",
      dashboard: "Dashboard",
      management: "Management",
      messages: "Message | Messages",
      password: "Password",
      registered: "Registered",
      sign_in: "Sign in",
      sign_out: "Sign out",
      studies: "Studies",
      title: "Viewmed, the digitouch medicine",
      user: "User"
    },
    charts: {
      title_one: "Users registered by type",
      title_two: "Users registered by date"
    },
    login: {
      passwordRules: "Password is required",
      userRules: "User is required"
    },
    table: {
      category: "Category",
      date: "Date",
      email: "Email",
      forename: "Forename",
      name: "Name",
      surname: "Surname",
      type: "Type",
      total_users_registered: "Total users registered",
      no_data_found: "No data found"
    },
    filters: {
      date: {
        day: "Day",
        last_month: "Last month",
        month: "Month",
        yesterday: "Yesterday"
      },
      patient: {
        external: "External",
        internal: "Internal"
      },
      user: {
        all: "All",
        institution: "Institution",
        patient: "Patient",
        professional: "Professional"
      },
      gender: {
        female: "Female",
        male: "Male"
      },
      title: {
        by_range: "By Range",
        by_select: "By Select",
        by_email: "By email",
        from: "From",
        gender: "Gender",
        select: "Select",
        specialty: "Specialty",
        type_of_professional: "@:table.type of professional",
        type_of_institution: "@:table.type of institution",
        type_of_patient: "@:table.type of patient",
        type_of_user: "@:table.type of user",
        until: "Until"
      },
      rules: {
        select_an_option: "@:filters.title.select an option",
        select_a_date: "@:filters.title.select a date"
      }
    },

    studies: {
      total_uploaded_studies: "Total uploaded studies",
      total_x_studies: "`{name} studies`"
    }
  }
};
