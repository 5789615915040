"use strict";

// import { list_institutions } from "../../../../services/institution";
import { institutions_agg_imagings } from "../../../../services/studies.js";
import { list_institutions_cf } from "@/services/institution";
import moment from "moment";

export default {
  name: "filters",
  data: () => ({
    dates: [],
    dateSelected: null,
    finalDate: null,
    initialDate: null,
    listedInstitutions: [],
    menu1: false,
    menu2: false,
    range: "bySelect",
    rules: {
      dayRules: [(v) => !!v || ""],
      datePickerOneRules: [(v) => !!v || ""],
      datePickerTwoRules: [(v) => !!v || ""],
      institutionRules: [(v) => !!v || ""],
    },
    selectedInstitution: null,
    valid: true,
  }),
  async mounted() {
    this.listedInstitutions = await list_institutions_cf();
  },
  created() {
    this.setInternationalization();
    // this.getInstitutions();
  },
  methods: {
    async download_file() {
      let date = Array();
      this.dateSelected
        ? (date = this.setDate(this.dateSelected))
        : (date = [
            moment(this.initialDate).format("YYYY-MM-DD HH:mm:ss.SSS"),
            moment(this.finalDate)
              .endOf("day")
              .format("YYYY-MM-DD HH:mm:ss.SSS"),
          ]);      
      let date_init = date[0].split(" ")[0].replaceAll("-","")
      let date_end = date[1].split(" ")[0].replaceAll("-","")
      let institution_id = this.selectedInstitution
      
      window.open(`https://gateway.viewmedonline.com/report/imaging?institution_id=${institution_id}&date_init=${date_init}&date_end=${date_end}`,"blank")

    },
    cleanDateSelected() {
      // Method that cleans date selects when clicked
      this.dateSelected = null;
      this.initialDate = null;
      this.finalDate = null;
    },
    isDateDisabledIni(date) {
      // Method that sets disabled dates on since datepicker
      return new Date().toISOString().substr(0, 10) >= date;
    },
    isDateDisabledTo(date) {
      // Method that sets disabled dates on until datepicker
      return date >= this.initialDate
        ? new Date().toISOString().substr(0, 10) >= date
        : null;
    },
    reset() {
      // Method for Reseting form
      this.$refs.form.reset();
    },
    setDate(value) {
      // Method that formats date by select with moment
      let end, start;
      switch (value) {
        case "today":
          start = moment().startOf("day").format("YYYY-MM-DD HH:mm:ss.SSS");
          end = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss.SSS");
          return [start, end];
        case "yesterday":
          start = moment()
            .subtract(1, "days")
            .startOf("day")
            .format("YYYY-MM-DD HH:mm:ss.SSS");
          end = moment()
            .subtract(1, "days")
            .endOf("day")
            .format("YYYY-MM-DD HH:mm:ss.SSS");
          return [start, end];
        case "month":
          start = moment().startOf("month").format("YYYY-MM-DD HH:mm:ss.SSS");
          end = moment().endOf("month").format("YYYY-MM-DD HH:mm:ss.SSS");
          return [start, end];
        case "last_month":
          start = moment()
            .subtract(1, "month")
            .startOf("month")
            .format("YYYY-MM-DD HH:mm:ss.SSS");
          end = moment()
            .subtract(1, "month")
            .endOf("month")
            .format("YYYY-MM-DD HH:mm:ss.SSS");
          return [start, end];
      }
    },
    setInternationalization() {
      // Sets i18n of data variables since the creation of the component
      let dates = [
        {
          text: this.$t("filters.date.day"),
          value: "today",
        },
        {
          text: this.$t("filters.date.yesterday"),
          value: "yesterday",
        },
        {
          text: this.$t("filters.date.month"),
          value: "month",
        },
        {
          text: this.$t("filters.date.last_month"),
          value: "last_month",
        },
      ];

      // Assign the data variable the array with i18n
      this.dates = dates;
    },
    // getInstitutions() {
    //   // Method for getting institutions registered in Viewmed
    //   let objAux = new Object();
    //   objAux = {
    //     limit: 100
    //   };
    //   list_institutions(objAux, sessionStorage.getItem("pussy")).then(
    //     result => {
    //       // Microservice for listing institutions
    //       this.listedInstitutions = result;
    //     }
    //   );
    // },
    async submit() {
      // Method that commits on Vuex storage id of institution
      if (this.$refs.form.validate()) {
        let nameUser;
        // gets name of user selected in form
        for (let i in this.listedInstitutions) {
          if (this.selectedInstitution == this.listedInstitutions[i]._id) {
            nameUser = this.listedInstitutions[i].name;
          }
        }
        // Commits in store loading
        await this.$store.commit({
          type: "studies/setStudies",
          loading: true,
          nameUser: nameUser,
        });
        await this.$store.commit({
          type: "chart/setProperties",
          loading: true,
        });
        this.getStudies();
      }
    },
    async getStudies() {
      // Method for gettting studies uploaded by the selected institution
      let objAux = new Object();
      let date = Array();
      this.dateSelected
        ? (date = this.setDate(this.dateSelected))
        : (date = [
            moment(this.initialDate).format("YYYY-MM-DD HH:mm:ss.SSS"),
            moment(this.finalDate)
              .endOf("day")
              .format("YYYY-MM-DD HH:mm:ss.SSS"),
          ]);
      // Fill the object objAux with dates and institution id
      objAux = {
        institution: this.selectedInstitution,
        from: date[0],
        to: date[1],
      };
      institutions_agg_imagings(objAux, sessionStorage.getItem("pussy")).then(
        (result) => {
          // Microservice that gets the studies
          this.dataStudies(result);
        }
      );
    },
    async dataStudies(data) {
      // Methods that sets total studies and organizes by id
      let total = 0;
      for (let i in data) {
        total = data[i].count + total;
      }
      // Commit studies in store
      await this.$store.commit({
        type: "studies/setStudies",
        studies: { data: data, total: total },
      });
      // Set Chart Properties
      await this.chartProperties(data);
      // this.reset();
    },
    chartProperties(data) {
      // method that sets properties of chart
      let ver = this.organizeData(data);
      let properties = new Object();
      properties = {
        // Chart Properties
        id: "myChart",
        type: "pie",
        data: {
          labels: ver.title,
          datasets: [
            {
              label: "Estudios",
              data: ver.numbers,
              backgroundColor: ver.backgroundColor,
              hoverBackgroundColor: "#D3D3D3",
              hoverBorderColor: ver.hoverBorderColor,
            },
          ],
        },
        options: {},
      };
      this.$store.commit({
        type: "chart/setProperties",
        properties: properties,
      });
    },
    organizeData(data) {
      // Method that organizes data for showing chart
      let newData = {
        title: [],
        numbers: [],
        backgroundColor: [],
        hoverBorderColor: [],
      };
      let colors = {
        ct: "#72D0EB",
        cr: "#00BEA4",
        xa: "#FF5000",
        mr: "#FFCC33",
        us: "#7E9BC0",
        dx: "#A0968C",
        px: "#00953A",
        sc: "#95D600",
        nn: "#26CAD3",
        rf: "#0033A0",
        ot: "#CC8341",
        ecg: "#8B8D8D",
        mg: "#FF7B9D",
        bi: "#f8ea47",
        dg: "#dcaa00",
        es: "#b67f00",
        ls: "#e27c00",
        pt: "#ffae75",
        rg: "#e16a2d",
        st: "#d4451d",
        tg: "#9a3820",
        rtimage: "#7d4a31",
        rtdose: "#dc9189",
        rtstruct: "#c86c61",
        rtplan: "#a85247",
        rtrecord: "#e2231a",
        hc: "#ff4338",
        io: "#c3002f",
        gm: "#ea1d76",
        sm: "#6d2a3c",
        xc: "#701c45",
        pr: "#a70064",
        au: "#9e4777",
        eps: "#a03058",
        hd: "#5f2a2b",
        sr: "#9f1b96",
        ivus: "#a25cbf",
        op: "#6b1e74",
        smr: "#783cbd",
        ar: "#430098",
        asmt: "#23145f",
        bdus: "#675dc6",
        bmd: "#4e4084",
        doc: "#725091",
        fid: "#41395f",
        iol: "#1f355e",
        ivoct: "#003b4c",
        ivos: "#5d89b4",
        ker: "#346094",
        ko: "#003594",
        len: "#000a8b",
        oam: "#2f7de1",
        oct: "#1d4f90",
        opm: "#006098",
        opt: "#008fbe",
        opv: "#008375",
        oss: "#00a19b",
        plan: "#67baaf",
        reg: "#007852",
        resp: "#00ad68",
        rwv: "#00af41",
        seg: "#6ca439",
        srf: "#b6bd00",
        stain: "#d1b400",
        va: "#9b8542",
      };
      for (let i in data) {
        // Assign newData fields values´
        newData.title.push(data[i]._id);
        newData.numbers.push(data[i].count);
        newData.backgroundColor.push(colors[data[i]._id.toLowerCase()]);
        newData.hoverBorderColor.push(colors[data[i]._id.toLowerCase()]);
      }
      return newData;
    },
  },
  computed: {
    initialDateFormatted: {
      // Final date formatted for showing on text field
      get() {
        return this.initialDate
          ? moment(this.initialDate).format("DD/MM/YYYY")
          : "";
      },
      set() {},
    },
    finalDateFormatted: {
      // Final date formatted for showing on text field
      get() {
        return this.finalDate
          ? moment(this.finalDate).format("DD/MM/YYYY")
          : "";
      },
      set() {},
    },
    loading() {
      // Loader of the component
      return this.$store.getters["studies/getLoading"];
    },
  },
};
