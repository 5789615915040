import api from "./api";

async function contact_us_query(data, token) {
  if (data) {
    let objAux = {
      method: "put",
      resource: "/contactUs/query",
      data
    };

    return (await api.execute(objAux, token)).data.documents;
  }
}

async function contact_us_update(data, token) {
  let objAux = {
    method: "put",
    resource: "/contactUs/update",
    data
  };

  return (await api.execute(objAux, token)).data.documents;
}

async function contact_us_delete(data, token) {
  let objAux = {
    method: "delete",
    resource: "/contactUs/delete",
    data
  };

  return (await api.execute(objAux, token)).data.documents;
}

async function contact_us_insert(data, token) {
  let objAux = {
    method: "delete",
    resource: "/contactUs/delete",
    data
  };

  return (await api.execute(objAux, token)).data.documents;
}

export {
  contact_us_query,
  contact_us_update,
  contact_us_delete,
  contact_us_insert
};
