import axios from "axios";

async function medical_report_query(data) {
    const instance  = await axios.create({
      baseURL: `https://gateway.viewmedonline.com/mr/${data.id_inst}/${data.acc_num}`,
      headers:{
        "Authorization": `Bearer ${sessionStorage.getItem("pussy")}`
      },
      json: true
    })({
      method: 'get',
    });
    
    return instance.data
  }

export { medical_report_query};
