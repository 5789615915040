import Vue from "vue"
import VueI18n from "vue-i18n"
import * as spanish from "./es"
import * as english from "./en"

Vue.use(VueI18n)

export default new VueI18n({
    locale: "es",
    messages: {
        en: english.default.lang,
        es: spanish.default.lang
    }
});