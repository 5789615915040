import api from "./api";

async function registered_query(data, token) {
  let objAux = {
    method: "put",
    resource: "/registered/query",
    data
  };

  return (await api.execute(objAux, token)).data.documents;
}

export { registered_query };
