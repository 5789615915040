import api from "./api";
import axios from "axios";

async function list_institutions(data, token) {
  let objAux = {
    method: "post",
    resource: "/contacts/institutions/list",
    data
  };

  return (await api.execute(objAux, token)).data.documents;
}

async function list_institutions_cf() {
  const instance  = await axios.create({
    baseURL: 'https://us-central1-viewmed-web.cloudfunctions.net/listInstitutions',
    json: true
  })({
    method: 'get',
  });
  
  return instance.data
}

async function list_host_institutions_cf() {
  const instance  = await axios.create({
    baseURL: 'https://gateway.viewmedonline.com/monitor/config',
    json: true
  })({
    method: 'get',
  });
  
  return instance.data
}

async function monitor_query(inst,init,end,type) {
  const instance  = await axios.create({
    baseURL: `https://gateway.viewmedonline.com/monitor/${inst}/${init}/${end}/${type}`,
    json: true
  })({
    method: 'get',
  });
  
  return instance.data
}

async function institution_query(id) {
  const instance  = await axios.create({
    baseURL: `https://gateway.viewmedonline.com/institutions/search/${id}`,
    json: true
  })({
    method: 'get',
  });
  
  return instance.data
}

export { list_institutions,list_institutions_cf,monitor_query,list_host_institutions_cf,institution_query };
