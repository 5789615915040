import { list_institutions_cf } from "@/services/institution";
import { medical_report_query } from "@/services/medical_report";
import {
  imaging_query,
  get_file,
  get_file_new,
  sharedImagingQuery,
  sharedImagingDelete,
  sharedImagingInsert,
  get_token_meddream,
} from "@/services/imaging";
import { send_notification } from "@/services/mail";
import { create_token_temporary, create_token_short } from "@/services/token";
import {
  notification_sms,
} from "@/services/notification";
import momment from "moment";
export default {
  name: "imaging-query",

  data: () => ({
    loading: false,
    autocomplete: null,
    items: [],
    query: {},
    filter_acc_num: null,
    filter_pat_id: null,
    filter_pat_email: null,
    studies: [],
    getDialogImagenology: false,
    getLoadingCard: false,
    getDialogImagenology2: false,
    getDialogMedicalReport: false,
    getLoadingCardMr: false,
    email: null,
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) =>
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "E-mail must be valid",
    ],
    valid: true,
    data_shared: {
      list_shared: [],
      modality: null,
      patient_name: null,
      study: null,
    },
    headers: [
      {
        text: "Nombre del Documento",
        value: "name_document",
      },
      {
        text: "Adjuntado por",
        value: "institution.name",
      },
      {
        text: "Fecha",
        value: "date_report",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    desserts: [
    ],
    data_ris: [],
    type_credential_user: "email",
    phone: null,
  }),
  async mounted() {
    this.items = await list_institutions_cf();
  },
  methods: {
    email_format_valid(email) {
        let re = /\S+@\S+\.\S+/;
        return re.test(email);
    },
    showReport(id) {
      window.open(
        `https://api.viewmedonline.com:8443/medical/report/pdf/${id}`,
        "_blank"
      );
    },
    async showReports(item) {
      this.getDialogImagenology2 = true;
      this.desserts = await Promise.all(
        item.medical_report.map((x) => {
          x.name_document = "Informe Medico";
          x.date_report = momment(
            x.date_report,
            "YYYY-MM-DD HH:mm:SS.SSS"
          ).format("YYYY-MM-DD");
          return x;
        })
      );
    },
    async deleteShared(item) {
      if (
        confirm(
          "Esta seguro que desea dejar de compartir el estudio con este usuario"
        )
      ) {
        this.getLoadingCard = true;
        await sharedImagingDelete(item._id, sessionStorage.getItem("pussy"));
        this.getDialogImagenology = false;
        this.getLoadingCard = false;
      }
    },
    async sharedQuery(item) {
      this.email = null;
      this.phone = null;
      this.getLoadingCard = true;
      this.getDialogImagenology = true;
      this.data_shared.modality = item.modality;
      this.data_shared.patient_name = item.patient_name;
      this.data_shared.study = item._id;
      this.data_shared.list_shared = [];
      this.data_shared.path = item.path;
      this.data_shared.iuid = item.iuid;
      this.data_shared.medical_report = item.medical_report;
      this.data_shared.list_shared = await sharedImagingQuery(
        {
          imaging: item._id,
          user_sharing: this.autocomplete.email,
        },
        sessionStorage.getItem("pussy")
      );
      this.data_shared.list_shared = this.data_shared.list_shared.filter(
        (v, i, a) =>
          a.findIndex(
            (t) =>
              t.user_sharing === v.user_sharing &&
              t.user_receiving === v.user_receiving
          ) === i
      );
      this.getLoadingCard = false;
    },
    format_date(date) {
      date = momment(date, "YYYY-MM-DD HH:mm:SS.SSS").format(
        "YYYY-MM-DD HH:mm"
      );
      return date;
    },
    async visorFda(val) {
      let obj_study_view = {
        study_uuid: val.iuid,
        storage: val.path != "" ? "Dcm4chee" : "GoogleHealth",
      };
      let token = (await get_token_meddream(obj_study_view)).document;
      window.open("http://fda.viewmedonline.com/?token=" + token);
    },
    async showDataRis(acc_num) {
      this.getLoadingCardMr = true;
      this.getDialogMedicalReport = true;
      let { data, report } = (
        await medical_report_query({
          acc_num: acc_num,
          id_inst: this.autocomplete._id,
        })
      ).document;

      this.getLoadingCardMr = false;
      let id_cont = 1;
      data.report = report;
      data = Object.keys(data).map((x) => {
        let obj = {};
        obj.id = id_cont++;
        obj.name = `${x}:`;
        obj.value = `${data[x]}`;
        return obj;
      });
      this.data_ris = data;
    },
    async sharedStudy() {
      this.getLoadingCard = true;
      let user_credential =
        this.type_credential_user == "email" ? this.email : `+${this.phone}`;
      let data_shared = {
        imaging: this.data_shared.study,
        user_sharing: this.autocomplete.email,
        user_receiving: user_credential,
      };
      let study_shared = (
        await sharedImagingQuery(data_shared, sessionStorage.getItem("pussy"))
      )[0];
      if (study_shared) {
        alert("Este estudio ya esta compartido con este usuario");
      } else {
        await sharedImagingInsert(data_shared, sessionStorage.getItem("pussy"));

        let data_token_temporary = {
          study: this.data_shared.iuid,
          storage: this.data_shared.path ? "Dcm4chee" : "GoogleHealth",
          email: user_credential,
          duration: 7,
        };
        let token_temporary = await create_token_temporary(
          data_token_temporary
        );

        let token_temporary_short = await create_token_short({
          url: `${token_temporary}`,
        });

        if (this.type_credential_user == "email") {
          await send_notification({
            template: 1,
            name: this.autocomplete.name,
            to: user_credential,
            type_study: this.data_shared.modality,
            email_center: this.autocomplete.email,
            phone_center: null,
            sct:token_temporary_short
          });
          if (this.data_shared.medical_report.length > 0) {
            await send_notification({
              template: 5,
              name: this.autocomplete.name,
              to: user_credential,
              type_study: this.data_shared.modality,
              email_center: this.autocomplete.email,
              phone_center: null,
              report_id: this.data_shared.medical_report[0]._id,
            });
          }          
        } else {
          // await notification_ws_queue({
          //   message: `https://imaging.viewmedonline.com/redirect/${
          //     token_temporary_short
          //   }`,
          //   phone: user_credential,
          // }); //link de estudio
          await notification_sms({
            name: `${this.autocomplete.name}`,
            typeStudy: `${this.data_shared.modality}`,
            link:`https://imaging.viewmedonline.com/redirect/${token_temporary_short}`,
            template: 1,
            toNumber:user_credential
          })           
          if (this.data_shared.medical_report.length > 0) {
            // await notification_ws_queue({
            //   message: `https://api.viewmedonline.com:8443/medical/report/pdf/${
            //     this.data_shared.medical_report[0]._id
            //   }`,
            //   phone: user_credential,
            // }); //link de informe
            await notification_sms({
              name: `${this.autocomplete.name}`,
              typeStudy: `${this.data_shared.modality}`,
              link:`https://api.viewmedonline.com:8443/medical/report/pdf/${this.data_shared.medical_report[0]._id}`,
              template: 5,
              toNumber:user_credential
            })            
          }
          // await notification_ws({
          //   toNumber: `whatsapp:${user_credential}`,
          //   template: 1,
          //   name: `${this.autocomplete.name}`,
          //   typeStudy: `${this.data_shared.modality}`,
          //   // image:
          //   //   "https://storage.googleapis.com/viewmed-static/static/viewmed-logo.png",
          // });          
        }

        this.getDialogImagenology = false;
      }
      this.getLoadingCard = false;
    },
    async submit() {
      if (this.autocomplete) {
        this.loading = true;
        this.query = {};
        this.query.role = "Institution";
        if (this.filter_acc_num)
          this.query.accession_number = this.filter_acc_num;
        if (this.filter_pat_id) this.query.patient_id = this.filter_pat_id;
        if (this.filter_pat_email) {
          let images_ids = (
            await sharedImagingQuery(
              {
                user_sharing: this.autocomplete.email,
                user_receiving: this.filter_pat_email,
              },
              sessionStorage.getItem("pussy")
            )
          ).map((x) => x.imaging);

          this.query.ids =
            images_ids.length > 0 ? images_ids : ["1a11aaaa11a1a1111a11a1a1"];
          this.query.custom = true;
          this.query.role = "patient";
        }

        this.query.institution_id = this.autocomplete._id;
        this.query.page = 1;
        this.query.size = 20;
        this.studies = await Promise.all(
          (
            await imaging_query(this.query, sessionStorage.getItem("pussy"))
          )[0].list.map(async (x) => {
            if (x.thumbnails)
              x.image =
                x.path == ""
                  ? await get_file_new(x.thumbnails)
                  : await get_file(x.thumbnails);
            return x;
          })
        );
        this.loading = false;
      }
    },
  },
  computed: {},
  watch: {
    filter_acc_num() {
      this.submit();
    },
    filter_pat_id() {
      this.submit();
    },
    filter_pat_email() {
      this.submit();
    },
  },
};
