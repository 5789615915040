"use strict";

import filters from "./subComponents/filters/filters.vue";
import tableRegistered from "./subComponents/tableRegistered/tableRegistered.vue";

export default {
  name: "registered",
  components: {
    filters,
    tableRegistered
  },
  data() {
    return {
      show: false
    };
  },
  mounted() {
    this.show = true;
  },
  beforeDestroy() {
    this.$store.dispatch("registered/resetRegistedState");
  },
  computed: {
    dataTable() {
      return this.$store.getters["registered/getDataTable"];
    }
  }
};
