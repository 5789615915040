"use strict";
import filters from "./subComponents/filters/filters.vue";
import showMessages from "./subComponents/showMessages/showMessages.vue";

export default {
  name: "messages",
  data: function () {
    return {
      show: false
    };
  },
  components: {
    filters,
    showMessages
  },
  mounted() {
    this.show = true
  },
};
