"use strict";
import * as moment from "moment";
import { list_categories } from "../../../../services/category";
export default {
  name: "tableRegistered",
  data() {
    return {
      headers: [],
      categories: []
    };
  },
  methods: {
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    tableHeaders() {
      // Data table headers
      for (const item in this.dataTable.result[0]) {
        let text;
        switch (item) {
          case "control.created_at":
            text = this.$t("table.date");
            break;
          default:
            text = this.$t("table." + item);
            break;
        }
        this.headers.push({
          text: text,
          value: item
        });
      }
    },
    typeInternationalization(type) {
      if (type) {
        if (type == "Physician") {
          return this.$t("filters.user.professional");
        } else {
          return this.$t("filters.user.patient");
        }
      } else {
        return this.$t("filters.user.institution");
      }
    },
    matchCategory(category, type) {
      // Compare categories id
      if (category) {
        for (let i in this.categories) {
          if (this.categories[i].value == category) {
            return this.categories[i].text;
          }
        }
      } else {
        switch (type) {
          case "Patient":
            return this.$t("filters.person.external");
          case "Internal Patient":
            return this.$t("filters.person.internal");
          default:
            return "--";
        }
      }
      this.$store.commit({
        type: "registered/setRegistered",
        loading: false
      });
    },
    getCategories() {
      // List institution categories
      list_categories({
        type: "institution",
        token: sessionStorage.getItem("pussy")
      })
        .then(result => {
          for (let i in result) {
            this.categories.push({
              text: result[i].name[this.$i18n.locale],
              value: result[i]._id
            });
          }
        })
      // List physician categories
      list_categories({
        token: sessionStorage.getItem("pussy")
      })
        .then(result => {
          for (let i in result) {
            this.categories.push({
              text: result[i].name[this.$i18n.locale],
              value: result[i]._id
            });
          }
        })
    }
  },
  created() {
    this.tableHeaders();
    this.getCategories();
  },
  computed: {
    dataTable() {
      let aux = [];
      let objAux = {};
      if (this.$store.getters["registered/getDataTable"] === null) {
        return {
          result: [],
          count: 0
        };
      } else {
        for (const item of this.$store.getters["registered/getDataTable"]
          .result) {
          objAux = {
            id: item._id,
            name: item.name
              ? this.capitalize(item.name)
              : `${this.capitalize(item.forename)} ${this.capitalize(
                  item.surname
                )}`,
            email: item.email ? item.email : `--`,
            type: this.typeInternationalization(item.type),
            category: this.matchCategory(item.category, item.type),
            "control.created_at": moment(item.control.created_at).format(
              "DD-MM-YYYY"
            )
          };
          aux.push(objAux);
        }
        // End Loading
        this.$store.commit({
          type: "registered/setRegistered",
          loading: false
        });
        return {
          result: aux,
          count: this.$store.getters["registered/getDataTable"].count
        };
      }
    },
    loading() {
      return this.$store.getters["registered/getLoading"];
    }
  }
};
