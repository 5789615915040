export default {
  props: {
    source: String
  },
  data: () => ({
    drawer: null,
    badge: 0,
    sidebar: [],
    navigationsTop: [],
    value:"favorites",
    sheet: false,
    list_bi: [
      { icon: 'mdi-account-check', title: 'Registrados',to:"registered" },
      { icon: 'cloud_upload', title: 'Estudios',to:"studies" },
      { icon: 'mdi-account-details', title: 'Usuarios',to:"user" },
      { icon: 'mdi-chart-areaspline', title: 'Monitereo de centros',to:"monitoring" },
    ],
  }),
  methods: {
    go_documentation() {
      window.open("https://octagonal-puffin-09a.notion.site/be69f82afcb24b00bf19049ffbecbb60?v=f2b2ffbef08441e1a42105cfb55000bd", "_blank");
    },
    restartCount(val) {
      // Checks if restarts count of inactivity time or signs out
      if (val) {
        // Restart Count
        this.$store.commit({
          type: "user/setUser",
          activeUser: true,
          showDialog: false
        });
      } else {
        // Sign Out
        this.$store.commit({
          type: "user/setUser",
          activeUser: false,
          showDialog: false
        });
        sessionStorage.clear();
        this.$router.push("/");
      }
    },
    loadSidebar() {
      let vm = this;
      let a = [
        {
          icon: "mdi-account-check",
          text: vm.$t("title.registered"),
          custom: false,
          route: "/registered"
        },
        {
          icon: "message",
          text: vm.$tc("title.messages", 2),
          custom: false,
          route: "/messages"
        },
        {
          icon: "cloud_upload",
          text: vm.$t("title.studies"),
          custom: false,
          route: "/studies"
        }  ,
        {
          icon: "mdi-account-details",
          text: vm.$t("title.user"),
          custom: false,
          route: "/user"
        },
        {
          icon: "find_in_page",
          text: "Consultas Imagenología",
          custom: false,
          route: "/imaging-query"
        },
        {
          icon: "mdi-chart-areaspline",
          text: "Monitor de centros",
          custom: false,
          route: "/monitoring"
        }        
      ];
      vm.sidebar = a;
      
    },
    loadNavigationBar() {
      let vm = this;
      let top = [
        {
          icon: "exit_to_app",
          text: vm.$tc("title.sign_out", 2),
          custom: false,
          route: "/"
        }
      ];
      vm.navigationsTop = top;
    },
    sign_out() {
      this.clearStore();
      this.$router.push("/");
    },
    clearStore() {
      this.$store.dispatch("studies/resetStudiesState");
      this.$store.dispatch("registered/resetRegistedState");
      this.$store.dispatch("user/resetUserState");
      this.$store.dispatch("chart/resetChartState");
      sessionStorage.clear();
    }
  },
  created() {
    this.loadSidebar();
    this.loadNavigationBar();
  },
  mounted() {
    this.$i18n.locale = "es";
  },
  computed: {
    activeUser() {
      return this.$store.getters["user/getActiveUser"];
    },
    showDialog() {
      return this.$store.getters["user/getShowDialog"];
    }
  }
};
