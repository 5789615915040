"use strict";

import api from "./api";

async function listMedicalSpecialties(data, token) {
  let objAux = {
    method: "post",
    resource: "/specialities/query",
    data
  };

  return (await api.execute(objAux, token)).data.documents;
}

export { listMedicalSpecialties };
