const initialState = () => {
  return {
    loading: false,
    nameUser: null,
    studies: null
  };
};

const state = initialState();

const getters = {
  getLoading: state => state.loading,
  getNameUser: state => state.nameUser,
  getStudies: state => state.studies
};

const actions = {
  resetStudiesState({ commit }) {
    commit("resetState");
  }
};

const mutations = {
  setStudies(state, payload) {
    for (let prop in state) {
      if (payload[prop] != undefined) {
        state[prop] = payload[prop];
      }
    }
  },
  resetState(state) {
    Object.assign(state, initialState());
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
