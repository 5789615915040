const initialState = () => {
  return {
    language: null,
    role: null,
    activeUser: true,
    showDialog: false
  };
};

const state = initialState();

const getters = {
  getLang: state => state.language,
  getRole: state => state.role,
  getActiveUser: state => state.activeUser,
  getShowDialog: state => state.showDialog
};

const actions = {
  resetUserState({ commit }) {
    commit("resetState");
  }
};

const mutations = {
  setUser(state, payload) {
    for (let prop in state) {
      if (payload[prop] != undefined) {
        state[prop] = payload[prop];
      }
    }
  },
  resetState(state) {
    Object.assign(state, initialState());
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
