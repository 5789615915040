import axios from "axios";
const new_url_api = "https://gateway.viewmedonline.com"

const client_fc = axios.create({
  baseURL: new_url_api,
  json: true,
})
let objQuery = {};
export default {

  async execute_cf(obj) {
    if (obj.responseType) {
      objQuery = {
        method: obj.method,
        url: obj.resource,
        responseType: obj.responseType
      }
    } else {
      objQuery = {
        method: obj.method,
        url: obj.resource
      }
    }

    let headers = {
      "Access-Control-Allow-Origin": "*",
      "Authorization": `Bearer ${sessionStorage.getItem("pussy")}`
    }


    objQuery.headers = headers

    if (obj.method == "get") {
      objQuery.params = obj.data
    } else {
      objQuery.data = obj.data
    }

    try {
      return await client_fc(objQuery)
    } catch (error) {
      return {
        error: true,
        status: error.response.status
      };
    }
  },  
  
  async execute(obj, token) {
    objQuery = {
      method: obj.method,
      url: obj.resource
    };

    let headers = {
      Authorization: token
    };

    if (token) {
      objQuery.headers = headers;
    }
    if (obj.method == "get") {
      objQuery.params = obj.data;
    } else {
      objQuery.data = obj.data;
    }
    return await axios.create({
      baseURL: process.env.VUE_APP_ROOT_API,
      json: true
    })(objQuery);
  },
  showImaging(fileId) {
    return new Promise((resolve) => {
      let xhr = new XMLHttpRequest();
      xhr.open("GET", process.env.VUE_APP_ROOT_API + "/get_file/" + fileId)
      xhr.setRequestHeader("Authorization", sessionStorage.getItem("pussy"))
      xhr.responseType = "arraybuffer"
      xhr.onload = function () {
        let arraybufferView = new Uint8Array(this.response);
        let blob = new Blob([arraybufferView], { type: "image/png" });
        let urlCreator = window.URL || window.webkitURL;
        let imageUrl = urlCreator.createObjectURL(blob);
        resolve(imageUrl)
      }
      xhr.send()
    })
  },
  showImagingNew(fileId) {
    return new Promise((resolve) => {
      let xhr = new XMLHttpRequest();
      xhr.open("GET", `${new_url_api}/file/${fileId}`)
      xhr.setRequestHeader("Authorization", `Bearer ${sessionStorage.getItem("pussy")}`)
      xhr.responseType = "arraybuffer"
      xhr.onload = function () {
        let arraybufferView = new Uint8Array(this.response);
        let blob = new Blob([arraybufferView], { type: "image/png" });
        let urlCreator = window.URL || window.webkitURL;
        let imageUrl = urlCreator.createObjectURL(blob);
        resolve(imageUrl)
      }
      xhr.send()
    })
  },  
};
