import Vue from "vue";
import Router from "vue-router";
import home from "./home";
// import layout from './layout'

let routeList = new Array();
let routes = routeList.concat(home);

const router = new Router({
  mode: "history",
  routes
});

Vue.use(Router);

export default router;
