"use strict";

const registered = () => import("../components/registered/registered.vue");
const home = () => import("../components/layouts/baseline.vue");
const login = () => import("../components/login/login.vue");
const messages = () => import("../components/messages/messages.vue");
const studies = () => import("../components/studies/studies.vue");
const user = () => import("../components/user/user.vue");
const imaging_query = () => import("../components/imaging-query/imaging-query.vue");
const monitoring = () => import("../components/monitoring/monitoring.vue");

export default [
  {
    path: "/registered",
    component: home,
    children: [
      {
        path: "/registered",
        name: "registered",
        component: registered
      },
      {
        path: "/messages",
        name: "messages",
        component: messages
      },
      {
        path: "/studies",
        name: "studies",
        component: studies
      },
      {
        path: "/user",
        name: "user",
        component: user
      },
      {
        path: "/imaging-query",
        name: "imaging-query",
        component: imaging_query
      },
      {
        path: "/monitoring",
        name: "monitoring",
        component: monitoring
      }
    ]
  },
  {
    path: "/",
    name: "login",
    component: login
  }
];
