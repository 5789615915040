"use strict";

import moment from "moment";
import { listMedicalSpecialties } from "../../../../services/speciality";
import { registered_query } from "../../../../services/registered";
import { list_categories } from "../../../../services/category";

export default {
  name: "registered",
  data: function () {
    return {
      dateSelected: null,
      genders: [],
      initialDate: [],
      categories: [],
      categorySelected: null,
      dates: [],
      loadingStats: false,
      menu1: false,
      menu2: false,
      patients: [],
      personGender: null,
      range: "bySelect",
      rules: {},
      selectedSpecialty: null,
      selectedTypePatient: null,
      specialties: [],
      users: [],
      typeUser: "All",
      valid: true,
      email: null,
      id_timeout: null,
    };
  },
  methods: {
    saveDate() {
      if (this.initialDate.length == 2) {
        this.menu1 = false;
        this.$refs.menu1.save(this.initialDateFormatted);
        this.submit();
      }
    },
    cleanDateSelected() {
      // Clean date selects when clicked
      this.dateSelected = null;
      this.initialDate = [];
    },
    cleanTypeSelected() {
      // Clean date type selects when clicked
      this.selectedSpecialty = null;
      this.selectedTypePatient = null;
      this.categorySelected = null;
      this.personGender = null;
    },
    listSpecialties() {
      // Microservice that gets all medical specialties
      listMedicalSpecialties({}, sessionStorage.getItem("pussy")).then(
        (result) => {
          for (let i in result) {
            this.specialties.push({
              text: result[i].name[this.$i18n.locale],
              value: result[i]._id,
            });
          }
        }
      );
    },
    isDateDisabledIni(date) {
      // Returns dates disabled in the first date picker
      return new Date().toISOString().substr(0, 10) >= date;
    },
    isDateDisabledTo(date) {
      // Returns dates disabled in the second date picker using data form the first date selected in first date picker
      return date >= this.initialDate
        ? new Date().toISOString().substr(0, 10) >= date
        : null;
    },
    getCategories(typeUser) {
      // Gets Institution of Professional categories
      if (typeUser) {
        this.cleanTypeSelected();
        this.categories = [];
        let objAux = new Object();

        if (typeUser != "Patient") {
          objAux = {
            type: "institution",
          };

          //Microservice that gets categories by type
          list_categories(objAux, sessionStorage.getItem("pussy")).then(
            (result) => {
              for (let i in result) {
                this.categories.push({
                  text: result[i].name[this.$i18n.locale],
                  value: result[i]._id,
                });
              }
            }
          );
        }
      }
    },
    async getRegistered() {
      // Gets registered users data by filters
      let objAux = new Object();
      let result = [];

      if (this.dateSelected) {
        // By selected value
        result = await this.setDate(this.dateSelected);
      } else {
        // By date pickers
        result = await [
          moment(this.initialDate[0]).toISOString(),
          moment().format("DD") == moment(this.initialDate[1]).format("DD")
            ? moment().toISOString()
            : moment(this.initialDate[1]).endOf("day").toISOString(),
        ];
      }
      // Constructs object objAux
      if (this.email) {
        this.typeUser == "All";
        objAux = {
          type: this.typeUser,
          email: this.email,
        };
      } else if (this.typeUser == "All") {
        // Set dates when filters 'all' is selected
        objAux = {
          dateFrom: result[0],
          dateTo: result[1],
        };
      } else {
        // Set the object with all the filters selected
        objAux = {
          dateFrom: result[0],
          dateTo: result[1],
          type: this.typeUser ? this.typeUser : null,
          category: this.categorySelected ? this.categorySelected : null,
          typePatient: this.selectedTypePatient
            ? this.selectedTypePatient
            : null,
          gender: this.personGender ? this.personGender : null,
          specialty: this.selectedSpecialty ? this.selectedSpecialty : null,
        };
      }

      // Microservice that gets registered with filters filled in the objAux
      await registered_query(objAux, sessionStorage.getItem("pussy")).then(
        (result) => {
          // Commit in vuex store data from registered users
          this.$store.commit("registered/setRegistered", {
            dataTable: result,
          });
          this.loadingStats = false;
        }
      );
    },
    setDate(value) {
      // Sets ISO Dates for search by selected value
      let end, start;
      switch (value) {
        case "today":
          start = moment().startOf("day").toISOString();
          end = moment().toISOString();
          return [start, end];
        case "yesterday":
          start = moment().subtract(1, "days").startOf("day").toISOString();
          end = moment().subtract(1, "days").endOf("day").toISOString();
          return [start, end];
        case "month":
          start = moment().startOf("month").toISOString();
          end = moment().toISOString();
          return [start, end];
        case "last_month":
          start = moment().subtract(1, "months").startOf("month").toISOString();
          end = moment().subtract(1, "months").endOf("month").toISOString();
          return [start, end];
      }
    },
    setInternationalization() {
      // Sets translations for texts inside the web
      let vm = this;

      let dates = [
        {
          text: vm.$t("filters.date.day"),
          value: "today",
        },
        {
          text: vm.$t("filters.date.yesterday"),
          value: "yesterday",
        },
        {
          text: vm.$t("filters.date.month"),
          value: "month",
        },
        {
          text: vm.$t("filters.date.last_month"),
          value: "last_month",
        },
      ];
      let users = [
        {
          text: vm.$t("filters.user.all"),
          value: "All",
        },
        {
          text: vm.$t("filters.user.patient"),
          value: "Patient",
        },
        {
          text: vm.$t("filters.user.professional"),
          value: "Physician",
        },
        {
          text: vm.$t("filters.user.institution"),
          value: "Institution",
        },
      ];
      let patients = [
        {
          text: vm.$t("filters.person.internal"),
          value: "internal patient",
        },
        {
          text: vm.$t("filters.person.external"),
          value: "patient",
        },
      ];
      let genders = [
        {
          text: vm.$t("filters.gender.male"),
          value: "male",
        },
        {
          text: vm.$t("filters.gender.female"),
          value: "female",
        },
      ];
      let rules = {
        dayRules: [(v) => !!v || this.$t("filters.rules.select_an_option")],
        datePickerOneRules: [
          (v) => !!v || this.$t("filters.rules.select_a_date"),
        ],
        datePickerTwoRules: [
          (v) => !!v || this.$t("filters.rules.select_a_date"),
        ],
        typeRules: [(v) => !!v || this.$t("filters.rules.select_an_option")],
      };

      // Assign to data variables the corresponding array
      vm.dates = dates;
      vm.users = users;
      vm.genders = genders;
      vm.patients = patients;
      vm.rules = rules;
    },
    submit() {
      // Validate the rules of filters
      if (this.$refs.form.validate()) {
        // Start loading progress component
        this.loadingStats = true;
        // Commits loading in Vuex store
        this.$store.commit({
          type: "registered/setRegistered",
          loading: true,
        });
        this.getRegistered();
      }
    },
    email_format_valid(email) {
      let re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
  },
  created() {
    this.setInternationalization();
    this.listSpecialties();
    this.getCategories();
  },
  computed: {
    loading() {
      return this.$store.getters["registered/getLoading"];
    },
  },
  watch: {
    typeUser() {
      this.submit();
    },
    dateSelected() {
      this.submit();
    },
    email() {
      clearTimeout(this.id_timeout);
      this.id_timeout = setTimeout(() => {
        this.submit();
      }, 1000);
    },
  },
};
