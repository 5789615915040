import api from './api'
let objAux = {};

async function create_token() {
   objAux = {
      method: "get",
      resource: `/create/token`
   }
   let result = (await api.execute(objAux, false))
   if (result.error) {
      return false
   } else {
      return result.data.documents
   }
}

async function create_token_temporary(data) {
   let objAux = {
     method: "post",
     resource: "/temporary/access",
     data,
   };
 
   return (await api.execute_cf(objAux, true)).data.token;
 }

 async function create_token_short(data) {
   let objAux = {
     method: "post",
     resource: "/shortcut",
     data,
   };
 
   return (await api.execute_cf(objAux, true)).data.document;
 }

export {
   create_token,
   create_token_temporary,
   create_token_short
}