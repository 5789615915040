"use strict";

import * as contactUs from "@/services/contactUs.js";
import * as moment from "moment";

export default {
  name: "showMessages",
  data: () => ({
    deleteAll: false,
    erase: [],
    items: [],
    page: 1,
    loading: false,
    read: [],
    selectedAll: false,
    value: [],
    totalVisible: 0,
    count: 0,
    dialog:false,
    msj_show:""
  }),
  mounted() {
    this.getMessages();
  },
  methods: {
    changePage(page) {
      // Validate page
      if (page < 1) this.page = page = 1;
      if (page > this.numPages) this.page = page = this.numPages;
      this.getMessages();
    },
    getMessages() {
      let vm = this;
      let objAux = new Object();
      vm.loading = true;
      vm.value = [];
      vm.selectedAll = false;
      vm.trash = false;
      objAux = {
        body: {
          page: vm.page,
          limit: 25
        }
      };
      contactUs
        .contact_us_query(objAux, sessionStorage.getItem("pussy"))
        .then(result => {
          vm.items = result[0];
          vm.count = result[1];
          for (let i = 0; i < result[0].length; i++) {
            vm.value.push(false);
          }
          vm.loading = false;
        });
    },
    deleteSelected() {
      let vm = this;
      let arrayDelete = new Array();
      for (let i in vm.value) {
        if (vm.value[i]) {
          arrayDelete.push(vm.items[i]._id);
        }
      }

      contactUs
        .contact_us_delete(arrayDelete, sessionStorage.getItem("pussy"))
        .then(() => {
          vm.getMessages();
        });
    },
    nextPage() {
      if (this.page < this.numPages) {
        this.page++;
        this.changePage(this.page);
      }
    },
    previousPage() {
      if (this.page > 1) {
        this.page--;
        this.changePage(this.page);
      }
    },
    readMessage(obj) {
      this.dialog = true
      this.msj_show = obj.comment
      if (!obj.read) {
        contactUs
          .contact_us_update(obj, sessionStorage.getItem("pussy"))
      }
    },
    selectAll() {
      let vm = this;
      let show = vm.value;
      if (vm.selectedAll) {
        vm.selectedAll = false;
        vm.trash = false;
      } else {
        vm.selectedAll = true;
        vm.trash = true;
      }
      for (let i in show) {
        vm.value[i] = vm.selectedAll;
      }
    },
    timeSent(date) {
      if (date) {
        return moment(date).calendar(this.$i18n.locale, {
          calendar: {
            sameDay: "LT",
            nextDay: "[Tomorrow]",
            nextWeek: "dddd",
            lastDay: "[Yesterday]",
            lastWeek: "mm DD",
            sameElse: "DD/MM/YYYY"
          }
        });
      }
    }
  },
  computed: {
    notAllSelected() {
      let vm = this;
      let show = vm.value;
      let pressed = new Array();
      for (let i in show) {
        if (show[i]) {
          pressed.push(show[i]);
          if (show.length == pressed.length) {
            vm.trash = true;
            vm.selectedAll = true;
            return false;
          }
        }
      }
      if (pressed.length == 0) {
        vm.trash = false;
        vm.selectedAll = false;
        return false;
      } else {
        vm.trash = true;
        return true;
      }
    },
    numPages() {
      return Math.ceil(this.count / 25);
    }
  }
};
