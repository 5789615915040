"use strict";

import api from "./api";

async function institutions_agg_imagings(data, token) {
  let objAux = {
    method: "put",
    resource: "/institutions/agg/studies",
    data
  };

  return (await api.execute(objAux, token)).data.documents;
}

export { institutions_agg_imagings };
