"use strict";

import api from "./api";

async function list_categories(data, token) {
  let objAux = {
    method: "get",
    resource: "/tenant/category/:type",
    data
  };

  return (await api.execute(objAux, token)).data.documents;
}

export { list_categories };
