const initialState = () => {
  return {
    dataTable: null,
    loading: false
  };
};

const state = initialState();

const getters = {
  getDataTable: state => state.dataTable,
  getLoading: state => state.loading
};

const actions = {
  resetRegistedState({ commit }) {
    commit("resetState");
  }
};

const mutations = {
  setRegistered(state, payload) {
    for (let prop in state) {
      if (payload[prop] != undefined) {
        state[prop] = payload[prop];
      }
    }
  },
  resetState(state) {
    Object.assign(state, initialState());
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
