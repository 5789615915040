import api from "./api";

async function user_management_login(data) {
  let objAux = {
    method: "post",
    resource: "/users/management/login",
    data
  };
  return (await api.execute(objAux, true)).data;
}

async function user_search(data, token) {
  let objAux = {
    method: "get",
    resource: "/users/search/" + data.user
  };
  return (await api.execute(objAux, token)).data;
}

export { user_management_login, user_search };
