import api from "./api";

async function notification_insert(data) {
  let objAux = {
    method: "post",
    resource: "/notifications/insert",
    data,
  };

  return (await api.execute(objAux, true)).data;
}

async function notification_ws(data) {
  let objAux = {
    method: "post",
    resource: "/notification/ws",
    data,
  };

  return (await api.execute_cf(objAux, true)).data;
}

async function notification_sms(data) {
  let objAux = {
    method: "post",
    resource: "/notification/sms",
    data,
  };

  return (await api.execute_cf(objAux, true)).data;
}

async function notification_ws_queue(data) {
  let objAux = {
    method: "post",
    resource: "/queue/ws",
    data,
  };

  return (await api.execute_cf(objAux, true)).data;
}

export { notification_insert,notification_ws,notification_ws_queue,notification_sms };
