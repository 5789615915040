"use strict";

export default {
  name: "showStudies",
  data: () => ({}),
  methods: {

  },
  computed: {
    studies() {
      this.$store.commit({
        type: "studies/setStudies",
        loading: false
      });
      return this.$store.getters["studies/getStudies"];
    },
    loading() {
      return this.$store.getters["studies/getLoading"];
    },
    nameUser() {
      return this.$store.getters["studies/getNameUser"];
    }
  }
};
