import 'babel-polyfill'
import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from '../src/router/index'
import store from './store/store'
import vuetify from './plugins/vuetify'
import i18n from "./lang/i18n"
import '../src/assets/sass/viewmed.css'
import VueRouter from 'vue-router';
import VueMask from 'v-mask'
Vue.use(VueMask)
Vue.config.productionTip = false
Vue.use(VueRouter)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
