import {
    list_host_institutions_cf,monitor_query
} from "@/services/institution";
import moment from 'moment'
import Chart from "chart.js";
export default {
    name: "imaging-query",
    data: () => ({
        loading: false,
        autocomplete: null,
        items: [],
        type_charts:["RAM","CPU","NETWORK","STORAGE"],
        data_chart: {},
        date: [],
        menu: false,
        studies_stats:null


    }),
    async mounted() {
        this.items = (await list_host_institutions_cf()).data
    },
    methods: {
        formatDate: (x) => moment(x).format("YYYY-MM-DD HH:mm"),
        generate_chart(stats_obj) {
            for (const item of this.type_charts) {

                this.data_chart = {
                    data: {
                        datasets: [
                            {
                            backgroundColor: "#00000000",
                            borderColor:"#2AC0A7",
                            label: `Porcentaje de ${item} en uso`,
                            data: stats_obj[item.toLowerCase()],
                            tension: 0.1,
                            fill: false,
                        }]
                    }
                }
                new Chart(`${item.toLowerCase()}-chart`, {
                    type: 'line',
                    options: {
                        scales: {
                          xAxes: [{
                            type: 'time'
                          }]                       
                        }
                      },
                    data: this.data_chart.data
                });                
            }

        },
        async find_stats() {
            
            if(this.autocomplete && this.date.length == 2) {
                this.loading = true

                let data = await monitor_query(this.autocomplete,this.date[0],this.date[1],'resources')
                let ram = []
                let cpu = []
                let storage = []
                let network = []
                for (const x of data) {
                    ram.push({
                        "y": x.ram,
                        "x": x.createdAt
                      })
                      cpu.push({
                        "y": x.cpu,
                        "x": x.createdAt
                      })
                      storage.push({
                        "y": x.storage,
                        "x": x.createdAt
                      })
                      network.push({
                        "y": x.network,
                        "x": x.createdAt
                      })                                                                  
                   
                }
                let stats_obj ={ram,cpu,storage,network}
                this.generate_chart(stats_obj)
                this.loading = false                
            }

        },
        async find_studies() {
            
            if(this.autocomplete && this.date.length == 2) {
                this.loading = true

                let data = await monitor_query(this.autocomplete,this.date[0],this.date[1],'studies')
                this.studies_stats = data[0]
                // console.log(this.studies_stats);
                // let stats_obj ={ram,cpu,storage,network}
                // this.generate_chart(stats_obj)
                this.loading = false                
            }

        },        
        save (date) {
            if(date.length ==2){
                this.$refs.menu.save(date)
            }
          },
    },
    computed: {

    },
    watch: {
        autocomplete(){
            this.find_stats()
            this.find_studies()
        },
        "date"(){
            this.find_stats()
            this.find_studies()
        }
    }
};