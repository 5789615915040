"use strict";

import { user_management_login } from "./../../services/user.js";
import { Base64 } from "js-base64";

export default {
  name: "login",
  data: () => ({
    loading: false,
    login: {},
    rules: {},
    valid: true,
    show: false
  }),
  created() {
    this.rulesTranslations();
  },
  mounted() {
    this.show = true;
  },
  methods: {
    signIn() {
      // Method for sign in into viewmed management
      this.loading = true;
      if (this.$refs.form.validate()) {
        let objAux = new Object();
        this.$store.commit({
          // commit active user
          type: "user/setUser",
          language: "es",
          activeUser: true,
          showDialog: false
        });
        objAux = this.login;
        objAux.passwd = Base64.encode(this.login.passwd);

        user_management_login(objAux)
          .then(result => {
            // Microservice that validates user for login
            sessionStorage.setItem("pussy", result.documents);
          })
          .then(() => {
            this.loading = false;
            this.$router.push("/registered");
          })
          .catch(() => {
            this.loading = false;
            this.login.passwd = null;
          });
      } else {
        this.loading = false;
      }
    },
    rulesTranslations() {
      // Set internationalization for rules in current language
      this.rules = {
        user: [v => !!v || this.$t("login.userRules")],
        password: [v => !!v || this.$t("login.passwordRules")]
      };
    }
  }
};
