import axios from "axios";

async function query_action_logs(email,range_date) {
  const instance  = await axios.create({
    baseURL: `https://gateway.viewmedonline.com/logs/action/${email}/${range_date}`,
    headers:{
      "Authorization": `Bearer ${sessionStorage.getItem("pussy")}`,
      "Access-Control-Allow-Origin": "*",
    },
    json: true,
  })({
    method: 'get'
  });
  
  return (instance.data).document
}

export {query_action_logs};